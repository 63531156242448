"use client";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import style from "./Experience.module.scss";
import { Banner } from "@/components/common/Banner/Banner";
import { useRefStore } from "../../../store/ref-store";
import { ContactCiruclarButton } from "@/components/common/Buttons/ContactCiruclarButton/ContactCiruclarButton";
import gsap from "gsap";
import WordsFlip from "@/components/common/WordsFlip/WordsFlip";
import { useContact } from "@/provider/ContextForm";
import { useDeviceDetector } from "@/utils/useDeviceDetector";
import { TitleAnimation } from "@/components/common/TitleAnimation/TitleAnimation";

export const Experience = ({ messages, words, bannerWords }) => {
  const experienceRef = useRef(null);
  const buttonRef = useRef(null);
  const [exceedsWidth, setExceedsWidth] = useState(false);
  const { setIsContactOpen } = useContact();
  const { isMobile } = useDeviceDetector();

  useEffect(() => {
    if (typeof window !== undefined) {
      setExceedsWidth(window.innerWidth >= 1000);
    }

    useRefStore.setState({ experience: experienceRef.current });
  }, []);

  useLayoutEffect(() => {
    let ctx;

    if (isMobile !== null) {
      ctx = gsap.context(() => {
        if (buttonRef.current && experienceRef.current) {
          const smallWidth = window.matchMedia("(max-width: 1000px)").matches;

          gsap.to(buttonRef.current, {
            bottom: smallWidth ? "25%" : "72%",
            duration: 1.5,
            ease: "power3.out",
            delay: 0.7,
            scrollTrigger: {
              trigger: experienceRef.current,
              start: "top bottom",
              end: "center center",
            },
          });
        }
      });
    }

    return () => ctx?.revert();
  }, [isMobile, exceedsWidth]);
  return (
    <section className={style.sectionContainer} ref={experienceRef}>
      <div className={"container"}>
        <div className={`${style.textContainer}`}>
          <TitleAnimation
            messages={{
              first_word: messages.create_text,
            }}
          ></TitleAnimation>
          <WordsFlip words={words} />
          <p>{messages.description}</p>
        </div>
      </div>
      <div
        ref={buttonRef}
        className={style.buttonContainer}
        onClick={() => setIsContactOpen(true)}
      >
        <ContactCiruclarButton />
      </div>
      <Banner words={bannerWords} />
    </section>
  );
};
