import { create } from "zustand";

interface State {
  isAudioPlaying: boolean;
  startPlaying: () => void;
  stopPlaying: () => void;
}

interface LogoState {
  currentLogo: string | null;
  setLogo: (logo: string | null) => void;
}

export const useCursorStore = create<State>()((set) => ({
  isAudioPlaying: false,
  startPlaying: () => set((state) => ({ isAudioPlaying: true })),
  stopPlaying: () => set((state) => ({ isAudioPlaying: false })),
}));

export const useLogoStore = create<LogoState>((set) => ({
  currentLogo: null,
  setLogo: (logo) => set({ currentLogo: logo }),
}));
