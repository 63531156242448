import { useEffect, useRef, useState } from "react";

const CustomPointLight = ({
  intensity = 1,
  position = [0, 0, 0],
  colors = ["#FFFFFF"],
  isParty = false,
  partyTime = 2000,
}) => {
  const [currentColor, setColor] = useState(colors[0]);

  const pointLightRef = useRef();

  useEffect(() => {
    if (!isParty) return;

    const interval = setInterval(() => {
      let r = Math.floor(Math.random() * colors.length);
      setColor(colors[r]);
    }, partyTime);

    return () => clearInterval(interval);
  }, []);

  return (
    <pointLight
      ref={pointLightRef}
      intensity={intensity}
      position={[position[0], position[1], position[2]]}
      color={currentColor}
      castShadow
    />
  );
};

export default CustomPointLight;
