import { useFrame } from "@react-three/fiber";
import { useEffect, useMemo, useRef, useState } from "react";
import { sRGBEncoding, MeshMatcapMaterial } from "three";
import { clone } from "three/examples/jsm/utils/SkeletonUtils";
import useModelStore from "../../../store/model-store";
import { AnimationMixer } from "three";
import { useTexture } from "@react-three/drei";
import { degToRad } from "three/src/math/MathUtils";

export const Ducks = ({
  scale = 3.5,
  position = [0.5, -2, 0],
  rotation = [0, 0, 0],
}) => {
  const [model, setModel] = useState(null);
  const [animations, setAnimations] = useState(null);

  const { duoDuckModel } = useModelStore();

  const matcap = useTexture("/matcap_base_3.png");
  matcap.encoding = sRGBEncoding;

  const matcapMouthInsideMat = useMemo(
    () =>
      new MeshMatcapMaterial({
        matcap: matcap,
        color: "#95512B",
      }),
    [matcap]
  );

  const mixer = useRef<AnimationMixer>();

  const materialAffected = [
    "Ducks_Rig_V02:Shader_general",
    "Ducks_Rig_V01:Shader_general",
    "Ducks_Rig_V01:Shader_Brillos",
    "Ducks_Rig_V02:Shader_Brillos",
  ];

  const checkMaterial = (materialName) => {
    for (let i = 0; i < materialAffected.length; i++) {
      if (materialName == materialAffected[i]) return true;
    }

    return false;
  };

  useEffect(() => {
    if (duoDuckModel !== null) {
      const scene = duoDuckModel[0];
      const animations = duoDuckModel[1];

      const sceneClone = clone(scene);

      setAnimations(animations);
      setModel(sceneClone);

      sceneClone.traverse((child: any) => {
        if (child.name === "Mesh009_4" || child.name === "Mesh017_4")
          child.material = matcapMouthInsideMat;

        if (child.material && checkMaterial(child.material.name)) {
          child.material.specularIntensity = 1;
          child.material.roughness = 0.5;
        }
      });
    }
  }, [duoDuckModel]);

  useEffect(() => {
    if (animations !== null && model !== null) {
      mixer.current = new AnimationMixer(model);
      animations.forEach((clip) => {
        mixer.current.clipAction(clip).reset().play();
      });
    }
  }, [animations, model]);

  useFrame((_, delta) => {
    if (mixer.current == null) return;
    mixer.current.update(delta);
  });

  const modelRef = useRef(null);

  useEffect(() => {
    return () => {
      if (model) {
        if (mixer.current) {
          mixer.current.stopAllAction();
          mixer.current.uncacheRoot(model);
          mixer.current = null;
        }
        model.traverse((child) => {
          if (child.isMesh) {
            if (child.geometry) child.geometry.dispose();
            if (child.material) {
              if (Array.isArray(child.material)) {
                child.material.forEach((material) => material.dispose());
              } else {
                child.material.dispose();
              }
            }
          }
        });
      }
    };
  }, []);

  return (
    <>
      {model !== null && animations !== null && (
        <group
          scale={scale}
          position={[position[0], position[1], position[2]]}
          rotation={[
            degToRad(rotation[0]),
            degToRad(rotation[1]),
            degToRad(rotation[2]),
          ]}
        >
          <primitive ref={modelRef} object={model} />
        </group>
      )}
    </>
  );
};
