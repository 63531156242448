"use client";

import style from "./GoodBoys.module.scss";
import { useEffect, useRef } from "react";
import Wave from "react-wavify";

/*THREE JS*/
import { PerspectiveCamera } from "@react-three/drei";
import { MagneticChipButton } from "@/components/common/Buttons/MagneticChipButton/MagneticChipButton";

import { TitleAnimation } from "@/components/common/TitleAnimation/TitleAnimation";
import { degToRad } from "three/src/math/MathUtils";
import FollowPath, { IPathPoint } from "@/components/common/3D/FollowPath";
import { BreakPointProps, useResponsive3D } from "@/utils/useResponsive3D";
import FollowWave from "@/components/common/3D/FollowWave";
import Duck from "../../common/3D/Duck";
import { AnimationStateKeys, useDuck } from "@/provider/DuckContext";
import Scene from "@/components/common/3D/Scene";

export const GoodBoys = ({ messages }) => {
  const containerBoys = useRef(null);
  const waveRef = useRef(null);
  const endTriggerRef = useRef(null);

  const path1900: IPathPoint[] = [
    {
      properties: [
        {
          key: "rotation",
          value: {
            x: degToRad(-10),
            y: degToRad(-15),
            duration: 0.025,
          },
        },
        {
          key: "call",
          value: () => {
            handleDuckAnimation(AnimationStateKeys.GREETINGS);
          },
        },
      ],
    },
    {
      properties: [
        {
          key: "rotation",
          value: {
            y: degToRad(-90),
            duration: 0.15,
          },
        },
        {
          key: "position",
          value: {
            x: 0,
            y: 3,
            z: -0.5,
          },
        },
        {
          key: "scale",
          value: {
            x: 1.5,
            y: 1.5,
            z: 1.5,
            duration: 0.15,
          },
        },
      ],
    },
    {
      properties: [
        {
          key: "rotation",
          value: {
            y: degToRad(-90),
            duration: 0.15,
          },
        },
        {
          key: "position",
          value: {
            x: 0,
            y: 3,
            z: -0.5,
          },
        },
        {
          key: "scale",
          value: {
            x: 1.5,
            y: 1.5,
            z: 1.5,
            duration: 0.15,
          },
        },
      ],
    },
  ];

  const path1600: IPathPoint[] = [
    {
      properties: [
        {
          key: "rotation",
          value: {
            x: degToRad(-10),
            y: degToRad(-15),
            duration: 0.025,
          },
        },
        {
          key: "call",
          value: () => {
            handleDuckAnimation(AnimationStateKeys.GREETINGS);
          },
        },
      ],
    },
    {
      properties: [
        {
          key: "rotation",
          value: {
            y: degToRad(-90),
            duration: 0.15,
          },
        },
        {
          key: "position",
          value: {
            x: 0,
            y: 2,
            z: -1,
            duration: 0.15,
          },
        },
        {
          key: "scale",
          value: {
            x: 1.5,
            y: 1.5,
            z: 1.5,
            duration: 0.15,
          },
        },
      ],
    },
    {
      properties: [
        {
          key: "rotation",
          value: {
            y: degToRad(-90),
            duration: 0.15,
          },
        },
        {
          key: "position",
          value: {
            x: 0,
            y: 2,
            z: -1,
            duration: 0.15,
          },
        },
        {
          key: "scale",
          value: {
            x: 1.5,
            y: 1.5,
            z: 1.5,
            duration: 0.15,
          },
        },
      ],
    },
  ];

  const path1200: IPathPoint[] = [
    {
      properties: [
        {
          key: "rotation",
          value: {
            x: degToRad(-10),
            y: degToRad(-15),
            duration: 0.025,
          },
        },
        {
          key: "call",
          value: () => {
            handleDuckAnimation(AnimationStateKeys.GREETINGS);
          },
        },
      ],
    },
    {
      properties: [
        {
          key: "rotation",
          value: {
            y: degToRad(-90),
            duration: 0.15,
          },
        },
        {
          key: "position",
          value: {
            x: 0,
            y: 2,
            z: -2,
          },
        },
        {
          key: "scale",
          value: {
            x: 1.5,
            y: 1.5,
            z: 1.5,
            duration: 0.15,
          },
        },
      ],
    },
    {
      properties: [
        {
          key: "rotation",
          value: {
            y: degToRad(-90),
            duration: 0.15,
          },
        },
        {
          key: "position",
          value: {
            x: 0,
            y: 2,
            z: -2,
          },
        },
        {
          key: "scale",
          value: {
            x: 1.5,
            y: 1.5,
            z: 1.5,
            duration: 0.15,
          },
        },
      ],
    },
  ];

  const breakPoints: Array<BreakPointProps> = [
    {
      breakpoint: [300, 400],
      props: {
        position: [-12.5, -0.4, 1],
        rotation: [5, -45, 0],
        amplitude: 15,
        speed: 0.4,
        scale: 0,
      },
    },
    {
      breakpoint: [401, 1000],
      props: {
        position: [-12.5, 0.5, 1],
        rotation: [5, -45, 0],
        amplitude: 15,
        speed: 0.4,
        scale: 1,
      },
    },
    {
      breakpoint: [1001, 1200],
      props: {
        position: [0, -0.5, 3],
        rotation: [0, 0, 0],
        amplitude: 60,
        speed: 0.2,
        scale: 0,
        path: path1200,
      },
    },
    {
      breakpoint: [1201, 1600],
      props: {
        position: [-1, 0, 3],
        rotation: [0, 0, 0],
        amplitude: 60,
        speed: 0.2,
        scale: 1,
        path: path1600,
      },
    },
    {
      breakpoint: [1601, 1900],
      props: {
        position: [-1, 0.5, 3],
        rotation: [0, 0, 0],
        amplitude: 80,
        speed: 0.2,
        scale: 2,
        path: path1900,
      },
    },
  ];

  const { breakProps } = useResponsive3D({
    breakPoints: breakPoints,
  });

  const { handleDuckAnimation } = useDuck();

  useEffect(() => {
    if (!containerBoys.current) return;

    endTriggerRef.current =
      containerBoys.current.parentNode.lastChild.lastChild;
  }, []);

  return (
    <>
      <section className={`${style.goodBoysContainer}`} ref={containerBoys}>
        <div className="container">
          <div className={`${style.title}`}>
            <TitleAnimation
              messages={{
                first_word: messages.goodBoys,
                second_word: messages.good,
                third_word: messages.ideas,
              }}
              lastWords={style.lastWords}
            />
            <p className={style.description}>{messages.description}</p>
            <MagneticChipButton
              label={messages.button}
              variant="outline"
              onClick={() => null}
              href={`/nosotros`}
            />
          </div>
        </div>
        <div ref={waveRef} className={style.waveContainer}>
          <Wave
            fill="transparent"
            paused={false}
            style={{
              stroke: "#17082f",
              strokeWidth: `${"1px"}`,
              strokeDasharray: `110vw`,
              borderBottom: 0,
              borderTop: 0,
            }}
            options={{
              height: 50,
              amplitude: breakProps?.amplitude,
              speed: breakProps?.speed,
              points: 2,
            }}
          />
        </div>
        <div className={style.canvasContainer}>
          <Scene
            className="trigger"
            style={{
              pointerEvents: "none",
              position: "absolute",
              width: "100%",
              height: "900px",
              top: 0,
              zIndex: 25,
            }}
            persist
          >
            <FollowPath
              endTrigger={endTriggerRef.current}
              endOffset={-300}
              mobile={false}
              path={breakProps?.path}
            >
              <PerspectiveCamera
                makeDefault
                position={[
                  breakProps?.position[0],
                  breakProps?.position[1] + 3,
                  breakProps?.position[2] + 15,
                ]}
                rotation={[
                  degToRad(breakProps?.rotation[0] - 10),
                  degToRad(breakProps?.rotation[1]),
                  degToRad(breakProps?.rotation[2]),
                ]}
                fov={30}
              />
            </FollowPath>
            <FollowWave wave={waveRef.current?.lastChild} positionOffset={0.5}>
              <group>
                <Duck
                  position={[3.5, -2, 0]}
                  scale={5 + breakProps?.scale}
                  rotation={[0, degToRad(-65), 0]}
                  cursorAnimations
                  cursorAngleLimit={10}
                ></Duck>
              </group>
            </FollowWave>
          </Scene>
        </div>
      </section>
    </>
  );
};
