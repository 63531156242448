"use client";
import styles from "./Cases.module.scss";
import { useRef, useEffect, useCallback } from "react";

/*COMPONENTS*/
import { MagneticChipButton } from "@/components/common/Buttons/MagneticChipButton/MagneticChipButton";
import { TuxdiArrow } from "./TuxdiArrow";
import LinkTransition from "../../../transition/components/LinkTransition/LinkTransition";

/*STORE */
import { useRefStore } from "../../../store/ref-store";
import { TitleAnimation } from "@/components/common/TitleAnimation/TitleAnimation";
import { API_BASE } from "../../../constants/config";

function getElementHeight(el: HTMLElement) {
  const maxHeight = el.style.maxHeight;

  el.style.maxHeight = "none";

  const height = el.scrollHeight;

  el.style.maxHeight = maxHeight;

  return height;
}
interface ProjectItem {
  id: number;
  slug: string;
  containerClassName: string;
  backgroundColor: string;
  className: string;
  images: string[];
  title: string;
  description: string;
  tags: string[];
}

interface CasesProps {
  messages: {
    first_word: string;
    second_word: string;
    description: string;
    our_cases: string;
    projectsList: ProjectItem[];
  };
  handleHover?: () => void;
  handleLeave?: () => void;
}

export const Cases = ({ messages, handleHover, handleLeave }: CasesProps) => {
  const { isMobile } = useRefStore();
  const hoveredCase = useRef(null);
  const mousePos = useRef([0, 0]);

  const currentWindowWidth = useRef(null);
  const prevWindowWidth = useRef(null);

  //Handle window resize
  useEffect(() => {
    const handleResize = () => {
      if (typeof window !== undefined) {
        prevWindowWidth.current = currentWindowWidth.current;
        currentWindowWidth.current = window.innerWidth;

        if (
          prevWindowWidth.current >= 1000 &&
          currentWindowWidth.current < 1000
        ) {
          const items = document.querySelectorAll(`.${styles.caseInfo}`);

          const itemsArr = Array.from(items);

          itemsArr.forEach((item) => {
            const caseText = item.querySelector("p");

            if (
              caseText.style.maxHeight === "1px" ||
              caseText.style.maxHeight === ""
            ) {
              const caseTextHeight = getElementHeight(caseText);

              caseText.style.maxHeight = `1px`;
              caseText.offsetHeight;

              caseText.style.maxHeight = `${caseTextHeight}px`;
            }
          });
        }

        if (
          prevWindowWidth.current <= 999 &&
          currentWindowWidth.current >= 1000
        ) {
          const items = document.querySelectorAll(`.${styles.caseInfo}`);

          const itemsArr = Array.from(items);

          itemsArr.forEach((item) => {
            const caseText = item.querySelector("p");

            if (caseText.style.maxHeight !== "1px") {
              caseText.style.maxHeight = `1px`;
            }
          });
        }
      }
    };
    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //Play animation
  const onMouseEnter = useCallback((item, e?) => {
    if (currentWindowWidth.current < 1000) return;

    if (e) {
      mousePos.current = [e.clientX, e.clientY];
      hoveredCase.current = item;
    }
    const caseText: HTMLElement = document.querySelector(
      `.${item.containerClassName} > div > div > p`
    );

    if (caseText.style.maxHeight === "1px" || caseText.style.maxHeight === "") {
      const caseTextHeight = getElementHeight(caseText);

      caseText.style.maxHeight = `1px`;
      caseText.offsetHeight;

      caseText.style.maxHeight = `${caseTextHeight}px`;
    }

    document
      .querySelector(`.${item.containerClassName}`)
      .classList.add(`${styles.caseContainerHoverState}`);
  }, []);

  const onMouseLeave = useCallback((item) => {
    if (currentWindowWidth.current < 1000) return;

    hoveredCase.current = null;
    mousePos.current = null;

    const caseText: HTMLElement = document.querySelector(
      `.${item.containerClassName} > div > div > p`
    );

    caseText.style.maxHeight = `1px`;

    //Remove the class to revert the rest of the animations
    document
      .querySelector(`.${item.containerClassName}`)
      .classList.remove(`${styles.caseContainerHoverState}`);
  }, []);

  //Handle animations on scroll
  useEffect(() => {
    if (!isMobile) {
      const handleScroll = () => {
        if (hoveredCase.current !== null) {
          const element = document.getElementsByClassName(
            hoveredCase.current.containerClassName
          )[0];
          const elementBox = element.getBoundingClientRect();

          const result =
            mousePos.current[0] >= elementBox.left &&
            mousePos.current[0] <= elementBox.right &&
            mousePos.current[1] >= elementBox.top &&
            mousePos.current[1] <= elementBox.bottom;

          if (!result) {
            onMouseLeave(hoveredCase.current);
          }
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [isMobile, onMouseEnter, onMouseLeave]);

  if (!messages.projectsList) return null;

  return (
    <section className={`${styles.sectionContainer} backgroundContainer`}>
      <div className={`${styles.casesContainer} container`}>
        <div className={styles.ourProjects}>
          <TitleAnimation
            messages={{
              first_word: messages.first_word,
              second_word: messages.second_word,
            }}
          />
          <p className={styles.description}>{messages.description}</p>
          <MagneticChipButton
            label={messages.our_cases}
            variant="outline"
            onClick={() => null}
            href={`/proyectos`}
          />
        </div>

        <div className={`${styles.cases} cases`}>
          {messages?.projectsList?.map((item) => (
            <div
              className={styles.case}
              key={item.id}
              onPointerEnter={() => {
                if (handleHover) handleHover();
              }}
              onPointerLeave={() => {
                if (handleLeave) handleLeave();
              }}
              data-case={JSON.stringify(item)}
              data-active={"false"}
              data-hover="cases"
            >
              <LinkTransition
                key={item.id}
                href={"/proyectos/" + item.slug}
                className={`${styles.caseContainer} ${item.containerClassName}`}
                onMouseEnter={(e) => {
                  if (!isMobile) {
                    onMouseEnter(item, e);
                  }
                }}
                onMouseMove={(e) => {
                  if (!isMobile) {
                    onMouseEnter(item, e);
                  }
                }}
                onMouseLeave={() => {
                  if (!isMobile) {
                    onMouseLeave(item);
                  }
                }}
                data-hover="cases"
              >
                <TuxdiArrow
                  color={item.backgroundColor}
                  className={item.className}
                />
                <div className={styles.caseContent}>
                  <picture>
                    <img
                      src={`${API_BASE}${item.images[0]}`}
                      alt={`${item.title}`}
                      loading="lazy"
                    />
                  </picture>
                  <div className={styles.caseInfo}>
                    <span>{item.title}</span>
                    <p data-animate="false">{item.description}</p>
                    <ul>
                      {item.tags.map((chip, index) => (
                        <li key={index}>
                          {chip}
                          {index !== item.tags.length - 1 && <small>+</small>}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </LinkTransition>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
