import { useTexture } from "@react-three/drei";
import React, { useEffect, useMemo, useRef } from "react";
import * as THREE from "three";
import { degToRad } from "three/src/math/MathUtils";

interface InstancedSphereProps {
  count: number;
  radius: number;
  positions: Array<[number, number, number]>;
  rotations: Array<[number, number, number]>;
  scales: Array<number>;
}

const InstancedSpheres = React.memo(
  ({
    count = 10,
    radius = 1,
    positions,
    rotations,
    scales,
  }: InstancedSphereProps) => {
    const matrixRef = useRef(null);

    const instancedRef = useRef<THREE.InstancedMesh>();
    const texture = useTexture("/balltexture.jpg");
    const matcap = useTexture("/matcap_base_3.png");

    useMemo(() => {
      const tempMatrix = new THREE.Matrix4();
      const tempPosition = new THREE.Vector3();
      const tempRotation = new THREE.Euler();
      const tempScale = new THREE.Vector3();

      matrixRef.current = Array.from({ length: count }, (_, i) => {
        tempPosition.set(positions[i][0], positions[i][1], positions[i][2]);
        tempRotation.set(
          degToRad(rotations[i][0]),
          degToRad(rotations[i][1]),
          degToRad(rotations[i][2])
        );
        tempScale.set(scales[i], scales[i], scales[i]);

        tempMatrix.compose(
          tempPosition,
          new THREE.Quaternion().setFromEuler(tempRotation),
          tempScale
        );
        return tempMatrix.clone();
      });
    }, [count, positions, scales]);

    useEffect(() => {
      if (instancedRef.current && matrixRef.current) {
        matrixRef.current.forEach((matrix, i) => {
          instancedRef.current.setMatrixAt(i, matrix);
        });
        instancedRef.current.instanceMatrix.needsUpdate = true;
      }
    }, [matrixRef]);

    return (
      <instancedMesh ref={instancedRef} args={[null, null, count]}>
        <sphereGeometry args={[radius, 32, 32]} />
        <meshMatcapMaterial map={texture} matcap={matcap} color={"#fdc322"} />
      </instancedMesh>
    );
  }
);

InstancedSpheres.displayName = "InstancedSpheres";

export default InstancedSpheres;
