"use client";
import { useRef, useState, useEffect, useLayoutEffect } from "react";
import styles from "./Video.module.scss";
import { useCursorStore } from "../../../store/cursor-store";
import gsap from "gsap";
import { API_BASE } from "../../../constants/config";

export const Video = ({ videos }) => {
  const containerRef = useRef();
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");

  const { stopPlaying, startPlaying } = useCursorStore();

  const handleClick = () => {
    if (isPlaying && videoRef.current) {
      videoRef.current.muted = true;
      setIsPlaying(false);
      stopPlaying();
    } else if (videoRef.current) {
      videoRef.current.muted = false;
      setIsPlaying(true);
      startPlaying();
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [videoSrc]);
  useEffect(() => {
    const updateVideoSrc = () => {
      const selectedVideo =
        window.innerWidth < 1000
          ? videos.find((video: { type: string }) => video.type === "mobile")
          : videos.find((video: { type: string }) => video.type === "desktop");

      if (selectedVideo) {
        setVideoSrc(`${API_BASE}${selectedVideo.url}`);
      }
    };

    updateVideoSrc();
    window.addEventListener("resize", updateVideoSrc);

    return () => {
      window.removeEventListener("resize", updateVideoSrc);
    };
  }, [videos]);

  useLayoutEffect(() => {
    let tween;

    if (videoRef.current) {
      tween = gsap.to(videoRef.current, {
        scrollTrigger: {
          trigger: containerRef.current,
          start: "-=100px center",
          end: "bottom bottom",
          scrub: 0.5,
        },
        scale: 1,
      });
    }

    return () => tween?.kill();
  }, []);

  return (
    <section
      className={` container ${styles.sectionContainer}`}
      ref={containerRef}
    >
      <button
        aria-label="Sound control"
        className={`${styles.videoContainer}`}
        onClick={handleClick}
      >
        <video
          autoPlay
          playsInline
          ref={videoRef}
          loop
          muted
          className="video"
          data-hover="video"
        >
          <source src={videoSrc} type="video/webm" />
        </video>
      </button>
    </section>
  );
};
