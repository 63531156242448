import { useDeviceDetector } from "@/utils/useDeviceDetector";
import { useFrame, useThree } from "@react-three/fiber";
import { useEffect, useRef } from "react";
import { MathUtils } from "three";

interface CameraLookAtProps {
  position: [number, number, number];
  rotation: [number, number, number];
  factorRotation: number;
  factorPosition: number;
  lookAtOffset?: [number, number, number];
  xRotation?: boolean;
  yRotation?: boolean;
}

const CameraLookAt = ({
  position,
  rotation,
  factorRotation = 1,
  factorPosition = 1,
  lookAtOffset = [0, 0, 0],
  xRotation = true,
  yRotation = true,
}: CameraLookAtProps) => {
  const { camera, scene } = useThree();
  const { isMobile } = useDeviceDetector();

  const positionRef = useRef<[number, number, number]>([0, 0, 0]);

  useEffect(() => {
    positionRef.current = position;
    camera.position.set(position[0], position[1], position[2]);
    camera.rotation.set(rotation[0], rotation[1], rotation[2]);
  }, [position, isMobile]);

  useFrame(({ pointer }) => {
    if (isMobile) return;

    camera.position.set(
      xRotation
        ? -MathUtils.lerp(positionRef.current[0], pointer.x / factorRotation, 1)
        : positionRef.current[0],
      yRotation
        ? -MathUtils.lerp(positionRef.current[1], pointer.y / factorRotation, 1)
        : positionRef.current[1],
      positionRef.current[2]
    );

    camera.lookAt(
      scene.position.x + lookAtOffset[0],
      scene.position.y + lookAtOffset[1],
      scene.position.z + lookAtOffset[2]
    );

    camera.position.set(
      xRotation
        ? -MathUtils.lerp(positionRef.current[0], pointer.x / factorPosition, 1)
        : positionRef.current[0],
      yRotation
        ? -MathUtils.lerp(positionRef.current[1], pointer.y / factorPosition, 1)
        : positionRef.current[1],
      positionRef.current[2]
    );
  });

  return null;
};

export default CameraLookAt;
