"use client";
import { useTexture } from "@react-three/drei";
import React from "react";
import { DoubleSide } from "three";
import { degToRad } from "three/src/math/MathUtils";

export const CustomPlane = React.memo(() => {
  const shadowTexture = useTexture("/shadow.png");

  return (
    <mesh
      position={[2.5, -2, -5]}
      rotation={[degToRad(90), degToRad(0), degToRad(-135)]}
      scale={[-1, 1, 1]}
    >
      <planeGeometry args={[44, 44]} />
      <meshStandardMaterial
        map={shadowTexture}
        transparent={true}
        opacity={1}
        side={DoubleSide}
      />
    </mesh>
  );
});

CustomPlane.displayName = "CustomPlane";
