"use client";
import CustomPlane from "@/components/common/3D/CustomPlane";
import CustomPointLight from "@/components/common/3D/CustomPointLight";
import CustomSpotLight from "@/components/common/3D/CustomSpotLight";
import InstancedSpheres from "@/components/common/3D/InstancedSpheres";
import Scene from "@/components/common/3D/Scene";
import CameraLookAt from "@/components/common/CameraLookAt";
import IsologoTransition from "@/components/common/IsologoTransition/IsologoTransition";
import { TitleAnimation } from "@/components/common/TitleAnimation/TitleAnimation";
import { useGsapBurgerTimeline } from "@/utils/useGsapBurgerTimeline";
import { BreakPointProps, useResponsive3D } from "@/utils/useResponsive3D";
import { PerspectiveCamera } from "@react-three/drei";
import { useEffect, useRef } from "react";
import { degToRad } from "three/src/math/MathUtils";
import { useRefStore } from "../../../store/ref-store";
import { Ducks } from "./Ducks";
import style from "./Duo.module.scss";

export const Duo = ({ messages }) => {
  const containerDuo = useRef(null);

  const breakPoints: Array<BreakPointProps> = [
    {
      breakpoint: [300, 600],
      props: {
        position: [-0.75, 1, 4],
        rotation: [10, -10, 0],
      },
    },
    {
      breakpoint: [601, 1000],
      props: {
        position: [-1.75, 1, 2],
        rotation: [-10, -10, 0],
      },
    },
    {
      breakpoint: [1001, 1200],
      props: {
        position: [-1.75, 1, -0.5],
        rotation: [-10, -10, 0],
      },
    },
    {
      breakpoint: [1201, 1600],
      props: {
        position: [-1.75, 0.5, -2],
        rotation: [-10, -10, 0],
      },
    },
    {
      breakpoint: [1601, 1900],
      props: {
        position: [-1.5, 0, -2.5],
        rotation: [-10, -10, 0],
      },
    },
    {
      breakpoint: [1901, 2500],
      props: {
        position: [-1.25, 0, -2.5],
        rotation: [-10, -10, 0],
      },
    },
  ];

  const { breakProps } = useResponsive3D({
    breakPoints: breakPoints,
  });

  useEffect(() => {
    useRefStore.setState({ duo: containerDuo.current });
  }, []);

  useGsapBurgerTimeline({
    triggerClass: style.duoContainer,
    startAdjustment: 32,
  });

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <div className={style.duoBG} />
      <section className={style.duoContainer} ref={containerDuo}>
        <IsologoTransition />
        <div className={`${style.bodyContainer} container`}>
          <div className={style.textContainer}>
            <TitleAnimation
              messages={{
                first_word: messages.first_word,
                second_word: messages.second_word,
              }}
            />
            <p className={style.description}>{messages.description}</p>
          </div>
        </div>
        <div className={style.canvas}>
          <Scene
            style={{
              width: "100%",
              height: "125vh",
            }}
          >
            <fog attach="fog" args={["#17082F", 2, 60]} />
            <PerspectiveCamera
              makeDefault
              position={[
                breakProps?.position[0],
                breakProps?.position[1] + 0.5,
                breakProps?.position[2] + 10,
              ]}
              rotation={[
                degToRad(breakProps?.rotation[0] - 5),
                degToRad(breakProps?.rotation[1]),
                degToRad(breakProps?.rotation[2]),
              ]}
              fov={50}
            />
            <Ducks />
            <InstancedSpheres
              radius={1}
              count={6}
              positions={[
                [-2, -1.5, 0],
                [0, -1, -6],
                [11, 0, -5],
                [8.5, -1.5, -4.25],
                [-3.75, -1.5, 4],
                [2.25, -1.5, 5],
              ]}
              rotations={[
                [0, 0, 0],
                [0, 0, 0],
                [0, 0, 0],
                [0, 0, 0],
                [0, 0, 0],
                [0, 0, 0],
              ]}
              scales={[0.5, 1, 2, 0.5, 1, 1]}
            />
            <ambientLight intensity={1} />
            <CustomSpotLight intensity={50} position={[5, 5, 0]} />
            <CustomSpotLight intensity={25} position={[0, 5, 3]} />
            <CustomPointLight
              intensity={2.5}
              position={[0.25, -0.4, 2]}
              colors={["#FF00FF", "#58FFD0", "#FF4255", "#2ECDFF", "#B224FF"]}
              isParty
            />
            <CustomPlane position={[0, -2, 0]} color="#AF2EFF" />
            <CameraLookAt
              position={[
                breakProps?.position[0],
                breakProps?.position[1] + 0.5,
                breakProps?.position[2] + 10,
              ]}
              rotation={[
                degToRad(breakProps?.rotation[0] - 5),
                degToRad(breakProps?.rotation[1]),
                degToRad(breakProps?.rotation[2]),
              ]}
              factorRotation={3}
              factorPosition={3}
              lookAtOffset={[-0.5, -1, 0]}
              yRotation={false}
            />
          </Scene>
        </div>
      </section>
    </div>
  );
};
