"use client";
import React, { useRef } from "react";
import styles from "./Services.module.scss";
import ExpertiseList from "@/components/Services/Expertise/ExpertiseList/ExpertiseList";
import { TitleAnimation } from "@/components/common/TitleAnimation/TitleAnimation";
export const Services = ({ messages }) => {
  const triggerRef = useRef(null);
  if (!messages.cardsServices || messages.cardsServices.length === 0)
    return null;
  return (
    <section ref={triggerRef} className={styles.servicesContainer}>
      <div className={`container`}>
        <TitleAnimation
          messages={{
            first_word: messages.first_word,
            second_word: messages.second_word,
            third_word: messages.third_word,
          }}
          lastWords={styles.lastWords}
        />
      </div>
      <ExpertiseList cardsServices={messages.cardsServices} />
      <div className={styles.spacer}></div>
    </section>
  );
};
