//@ts-nocheck
"use client";
import React, { useRef } from "react";

import { Hero } from "@/components/Home/Hero/Hero";
import { Cases } from "@/components/Home/Cases/Cases";
import { Video } from "@/components/Home/Video/Video";
import { GoodBoys } from "@/components/Home/GoodBoys/GoodBoys";
import { Footer } from "@/components/layout/Footer/Footer";
import { Experience } from "@/components/Home/Experience/Experience";
import { Duo } from "@/components/Home/Duo/Duo";
import { Services } from "@/components/Home/Services/Services";
import { ExcellenceAwards } from "@/components/common/ExcellenceAwards/ExcellenceAwards";
import { SharedCanvas } from "@/components/common/SharedCanvas";
import HomeIsologoTransition from "@/components/Home/HomeIsologoTransition/HomeIsologoTransition";

import style from "./Section.module.scss";
import { AnimationStateKeys, useDuck } from "@/provider/DuckContext";

const SectionHome = ({ messages }) => {
  const pageWrapper = useRef(null);

  const { handleDuckAnimation } = useDuck();

  const handleHover = () => {
    handleDuckAnimation(
      Math.random() < 0.5 ? AnimationStateKeys.SIDE : AnimationStateKeys.YES
    );
  };

  return (
    <>
      <div className={`${style.pageWrapper} pageWrapper`} ref={pageWrapper}>
        <Hero messages={messages.titleHero} />
        {messages?.videos ? <Video videos={messages.videos} /> : null}
        <div className={`content homeDuckWrap`}>
          <GoodBoys messages={messages.goodBoysSection} />
          <Cases
            messages={messages.projectsSection}
            handleHover={handleHover}
          />
        </div>
        <Services messages={messages.servicesSection} />
        <Duo messages={messages.dynamicSection} />
        <Experience
          messages={messages.createSection}
          words={messages.words_experience}
          bannerWords={messages.bannerWords}
        />
        <ExcellenceAwards messages={messages.awardsSection} />
        <Footer />
      </div>
    </>
  );
};

export default SectionHome;
