"use client";

import styles from "./Hero.module.scss";

import { useRef } from "react";

import Duck from "../../common/3D/Duck";
import { BreakPointProps, useResponsive3D } from "@/utils/useResponsive3D";
import InstancedSpheres from "@/components/common/3D/InstancedSpheres";
import { degToRad } from "three/src/math/MathUtils";
import { PerspectiveCamera } from "@react-three/drei";
import { CustomPlane } from "../../common/CustomPlane";
import Scene from "@/components/common/3D/Scene";
import CameraLookAt from "@/components/common/CameraLookAt";

export const Hero = ({ messages }) => {
  const heroRef = useRef(null);
  const breakPoints: BreakPointProps[] = [
    {
      breakpoint: [300, 1000],
      props: {
        position: [-4.75, 1.5, 0],
        rotation: [0, -20, 0],
        duckRotation: -50,
      },
    },
    {
      breakpoint: [1001, 1200],
      props: {
        position: [-1, 0.75, 2],
        rotation: [0, 0, 0],
        duckRotation: -45,
      },
    },
    {
      breakpoint: [1201, 1600],
      props: {
        position: [-2, 0.65, 0],
        rotation: [0, 0, 0],
        duckRotation: -45,
      },
    },
    {
      breakpoint: [1601, 1900],
      props: {
        position: [-2, 0, 0],
        rotation: [0, 0, 0],
        duckRotation: -45,
      },
    },
    {
      breakpoint: [1901, 2500],
      props: {
        position: [-2.25, 0, 0],
        rotation: [0, 0, 0],
        duckRotation: -45,
      },
    },
  ];

  const { breakProps } = useResponsive3D({
    breakPoints: breakPoints,
  });

  return (
    <div className={styles.heroContainer}>
      <div className={styles.canvas}>
        <div className={styles.fade}></div>
        <Scene
          style={{
            width: "100vw",
            height: "125vh",
          }}
        >
          <PerspectiveCamera
            makeDefault
            position={[
              breakProps?.position[0],
              breakProps?.position[1] + 0.5,
              breakProps?.position[2] + 17,
            ]}
            rotation={[
              degToRad(breakProps?.rotation[0] - 5),
              degToRad(breakProps?.rotation[1] - 5),
              degToRad(breakProps?.rotation[2]),
            ]}
            fov={30}
          />
          <InstancedSpheres
            radius={1}
            count={4}
            positions={[
              [-0.4, -1.5, 0.8],
              [12.5, -0.5, -10],
              [-9, -1, -3.25],
              [-6.75, -1.54, -0.8],
              [-2.25, -1.7, 8.5],
            ]}
            rotations={[
              [-45, 0, 0],
              [0, 0, 0],
              [0, 50, 0],
              [0, 90, 0],
              [0, 0, 0],
            ]}
            scales={[0.5, 1.5, 1, 0.5, 0.3, 1]}
          />
          <Duck
            scale={4.5}
            position={[3.25, -1.99, 0]}
            rotation={[0, degToRad(breakProps?.duckRotation), 0]}
            cursorAnimations
            cursorAngleLimit={10}
          ></Duck>
          <CameraLookAt
            position={[
              breakProps?.position[0],
              breakProps?.position[1] + 0.5,
              breakProps?.position[2] + 17,
            ]}
            rotation={[
              degToRad(breakProps?.rotation[0] - 5),
              degToRad(breakProps?.rotation[1] - 5),
              degToRad(breakProps?.rotation[2]),
            ]}
            factorRotation={0.75}
            factorPosition={0.75}
            lookAtOffset={[-0.5, -0.75, 0]}
            yRotation={false}
          />
          <CustomPlane />
        </Scene>
      </div>
      <div className={`container`} ref={heroRef}>
        <div className={styles.titleContainer}>
          <h1 className="title-section">
            <p>
              <span>{messages.firstWord}</span>
              <span className="title-outline">{messages.secondWord}</span>
            </p>
            <p>
              <span>{messages.thirdWord}</span>
              <span className="title-outline">{messages.lastWord}</span>
            </p>
          </h1>
        </div>
      </div>
    </div>
  );
};
