import { useRef } from "react";

const CustomSpotLight = ({
  intensity = 1,
  position = [0, 0, 0],
  color = "#FFFFFF",
}) => {
  const spotLightRef = useRef();

  return (
    <spotLight
      ref={spotLightRef}
      intensity={intensity}
      position={[position[0], position[1], position[2]]}
      color={color}
      angle={5}
      penumbra={1}
      shadow-mapSize-width={1024}
      shadow-mapSize-height={1024}
      castShadow
    />
  );
};

export default CustomSpotLight;
