import gsap from "gsap";
import { PropsWithChildren, useLayoutEffect, useRef } from "react";
import ScrollTrigger from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

export interface IPathPointProperty {
  key: string;
  value: any;
}
export interface IPathPoint {
  properties: IPathPointProperty[];
}

export interface IPathProps {
  endTrigger: HTMLElement;
  endOffset?: number;
  path: IPathPoint[];
  mobile?: boolean;
  zIndex?: number;
}

const FollowPath = ({
  children,
  endTrigger,
  endOffset = 0,
  path = [],
  zIndex = 25,
}: PropsWithChildren<IPathProps>) => {
  const groupRef = useRef(null);
  const triggerRef = useRef(document.querySelector(".trigger"));
  const timelineRef = useRef<gsap.core.Timeline | null>(null);

  useLayoutEffect(() => {
    if (timelineRef.current) {
      timelineRef.current.scrollTrigger?.kill();
      timelineRef.current.kill();
      timelineRef.current = null;
    }

    if (path.length <= 0) return;

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".trigger",
        start: "center center",
        endTrigger: endTrigger,
        end: `bottom+=${endOffset} center`,
        pin: true,
        scrub: 1,
      },
      onStart: () => {
        triggerRef.current.parentNode["style"].zIndex = zIndex;
        triggerRef.current.parentNode["style"].pointerEvents = "none";
      },
      ease: "sine.inOut",
    });

    path.forEach(({ properties }) => {
      properties.forEach((point, index) => {
        if (point.key === "call") {
          timeline.call(point.value);
        } else {
          timeline.to(
            groupRef.current[point.key],
            point.value,
            index === 0 ? null : "<"
          );
        }
      });
    });

    timelineRef.current = timeline;

    return () => {
      if (timelineRef.current) {
        timelineRef.current.scrollTrigger?.kill();
        timelineRef.current.kill();
        timelineRef.current = null;
      }
    };
  }, [path]);

  return <group ref={groupRef}>{children}</group>;
};

export default FollowPath;
